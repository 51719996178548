<template>
  <Layout>
    <el-tabs v-model="activeName">
      <!-- eslint-disable vue/max-attributes-per-line -->
      <el-tab-pane label="审核管理" />
    </el-tabs>

    <div>
      <el-table
        v-loading="listInfo.loading"
        :data="listInfo.list"
      >
        <el-table-column
          label="包名"
          prop="packageName"
        />

        <el-table-column
          prop="downloadUrl"
          label="下载地址"
        />
        <el-table-column
          label="审核状态"
        >
          <template #default="{row}">
            <el-button v-if="row.openAudit === 1" @click="updateAudit(0,row)">
              关闭审核
            </el-button>
            <el-button v-else @click="updateAudit(1,row)">
              开启审核
            </el-button>
          </template>
        </el-table-column>

        <el-table-column
          label="修改时间"
          prop="updateTime"
          width="180px"
        />
        <el-table-column
          prop="operator"
          label="操作人"
        />
      </el-table>

      <Pagination
        :total="listInfo.total"
        :page.sync="formData.pageNum"
        :limit.sync="formData.pageSize"
        @pagination="getVersionBlackAndWhiteList"
      />
    </div>
  </Layout>
</template>

<script>
import {
  getAppList,
  saveAppOne
} from '@/api/blacklist.js'
export default {
  data () {
    return {
      formData: {
        platform: 'ios',
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      activeName: '0'
    }
  },
  created () {
    this.getVersionBlackAndWhiteList()
  },
  methods: {
    updateAudit (num, row) {
      this.$confirm('确定修改审核状态？', '提示', { type: 'warning ' })
        .then(() => {
          this.listInfo.loading = true
          saveAppOne({
            action: 'audit',
            params: { ...row, openAudit: num }
          })
            .then(res => {
              if (res.code === 200) {
                this.getVersionBlackAndWhiteList()
                this.$message.success('修改成功')
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        })
    },
    getVersionBlackAndWhiteList () {
      this.listInfo.loading = true
      getAppList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    }
  }
}
</script>

<style>

</style>
